#nav-dropdown{
  font-size: 16px;
  font-weight: 800;
  color: white;
}
div.dropdown-menu.show {
  background-color: #343A40;
}
a.dropdown-item:hover {
  background: none;
}
