$black: #1d1f20;
$orange: #ff9900;
$yellow: #fddc32;
$white: #fafafa;
$purple1: #a60af3;
$purple2: #7a00cc;
$purple3: #c03fff;

.bg-purple {
    color: #fff;
    background: -webkit-linear-gradient(110deg, $purple1 40%, rgba(0, 0, 0, 0) 30%), -webkit-radial-gradient(farthest-corner at 0% 0%, $purple2 70%, $purple3 70%);
    background: -o-linear-gradient(110deg, $purple1 40%, rgba(0, 0, 0, 0) 30%), -o-radial-gradient(farthest-corner at 0% 0%, $purple2 70%, $purple3 70%);
    background: -moz-linear-gradient(110deg, $purple1 40%, rgba(0, 0, 0, 0) 30%), -moz-radial-gradient(farthest-corner at 0% 0%, $purple2 70%, $purple3 70%);
    background: linear-gradient(110deg, $purple1 40%, rgba(0, 0, 0, 0) 30%), radial-gradient(farthest-corner at 0% 0%, $purple2 70%, $purple3 70%);
}
