.offer-board-ping {
  height: 10px;
  width: 10px;
  background-color: #ff7500;
  border-radius: 50%;
  position: absolute;
}
.top-left-ping {
  top: 30px;
  left: 30px;
}
.top-right-ping {
  top: 30px;
  right: 30px;
}
.bottom-left-ping {
  bottom: 30px;
  right: 30px;
}
.bottom-right-ping {
  bottom: 30px;
  left: 30px;
}

.offer-board-title {
	border: 2px solid orange;
}

.offer-coverlay {
  background: orange;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

:root {
    --bg: #fdfdfd;
    --highlight1: #ff9900;
    --highlight2: #ff0000;
    --color: #1a1e24;
    --font-number: Montserrat, Roboto, Helvetica, Arial, sans-serif;
    --font-head: "Space Mono", Consolas, Menlo, Monaco, "Courier New", monospace;
    --font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.item {
    display: block;
    clear: both;
    counter-increment: list;
    padding-top: 50px;
    padding-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.375;
    position: relative;
}

.item:before {
    font: bold 2.25rem/1 var(--font-number);
    content: counter(list);
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 1.5rem;
    color: var(--bg);
    background: var(--highlight1) linear-gradient(to bottom right, var(--highlight1) 25%, var(--highlight2));
    text-shadow: 0 0 2px var(--highlight1);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    shape-outside: ellipse();
    z-index: 1;
}

.item:after {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: var(--highlight1);
    z-index: -1;
    border-top-left-radius: 3px;
}


.headline {
    padding: 0rem 0 0 0;
    margin: 0 0 1rem 0;
}

.card:hover {
  box-shadow: 0 13px 20px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card-title-0 {
  background: #ffd699;
}
.card-title-1 {
  background: #ffd699;
}
.card-title-2 {
  background: #ffb84d;
}
.card-title-3 {
  background: #ff9900;
}

.circle {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  z-index: 0;
}

.left-circle {
  top: 3.5px;
  left: -1px;
}

.right-circle {
  top: 3.5px;
  right: -1px;
}

.ribbon {
  position: relative;
  background-size: cover;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
}

.wrap {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
}
.wrap:before, .wrap:after {
  content: "";
  position: absolute;
}
.wrap:before {
  width: 40px;
  height: 8px;
  right: 85px;
  background: #990000;
  border-radius: 8px 8px 0px 0px;
}
.wrap:after {
  width: 8px;
  height: 40px;
  right: 0px;
  top: 85px;
  background: #990000;
  border-radius: 0px 8px 8px 0px;
}
.ribbon6 {
  width: 190px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 30px;
  right: -50px;
  z-index: 1;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px dashed;
  box-shadow:0 0 0 3px red,  0px 21px 5px -18px rgba(0,0,0,0.6);
  background: red;
  text-align: center;
}

img {
  width: 100%;
  height: auto;
}

.offer-detail-header {
  border-bottom: 1px solid lightGray;
  line-height: 0.1em;
  color: orange;
}
.offer-detail-header span {
  background:#fff;
  padding-right: 10px;
  font-size: 24px;
  font-weight: bold;
}
.offer-details {
  font-size: 20px;
}
