.border-text {
	.border-text-title {
		display: inline-block;
		padding: 0 15px;
	}
	.border_left {
		display: inline;
		position: relative;
		&:before {
			content: '';
			display: inline-block;
			border-bottom: 2px solid orange;
			width: 20%;
			position: relative;
			top: -11px;
		}
		&:after {
			content: '';
			display: inline-block;
			border-bottom: 2px solid black;
			width: 50%;
			position: absolute;
			top: 8px;
			right: 0;
		}
	}
	.border_right {
		display: inline;
		position: relative;
		&:before {
			content: '';
			display: inline-block;
			border-bottom: 2px solid orange;
			width: 20%;
			position: relative;
			top: -3px;
		}
		&:after {
			content: '';
			display: inline-block;
			border-bottom: 2px solid black;
			width: 50%;
			position: absolute;
			bottom: 13px;
			left: 0;
		}
	}
}
.school-data {
	position: relative;
	z-index: 1;
	top: 20px;
	left: 10px;
	width: 200px;
	border: 2px solid orange;
}
.school-data-content {
	border-top: 2px solid #ffdb99;
	border-left: 2px solid #ffdb99;
}
.school-offer {
	position: relative;
	z-index: 1;
	top: 35px;
	left: 10px;
	width: 200px;
	border: 2px solid orange;
}
.bg-gold {
	background: linear-gradient(110deg, #fdcd3b 33%, rgba(0, 0, 0, 0) 33%), linear-gradient(110deg, #ffed4b 66%, #ffc608 66%);
}

.marquee {
	height: 300px;
	overflow: hidden;
	box-sizing: border-box;
	position: relative;
	border-top: 2px solid #ffdb99;
	border-left: 2px solid #ffdb99;
	font-size: 14px;
}

.marquee-content {
	animation: marquee 60s linear infinite;
}

.job-marquee-content {
	animation: marquee 80s linear infinite;
}

@keyframes marquee {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-100%);
	}
}

.service-card-title {
	position: relative;
	z-index: 1;
	top: 25px;
	width: 125px;
	border-bottom: 3px solid orange;
	margin:0 auto;
}
